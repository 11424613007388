import React from "react";
import { BoxWithImageProps } from "app/components/types/BoxWithImage";

export const BoxWithImage = ({ props }: { props: BoxWithImageProps }) => {
  return (
    <div className="w-full gap-y-4 sm:w-[240px] sm:h-[350px] lg:h-[300px] lg:w-[350px] border border-[#775dda] rounded-xl flex flex-col items-start justify-center p-8 bg-[#0e121a80]">
      <div>
        <img src={props.image} alt={props.title} />
      </div>
      <div className="text-white sm:text-xl xl:text-2xl font-bold">
        {props.title}
      </div>
      <div className="text-white sm:text-base xl:text-lg">
        {props.description}
      </div>
    </div>
  );
};
