/* eslint-disable array-callback-return */
import { PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { IProductModel } from 'app/Model/products';
import { productSaga } from './saga';
import {
  ICreateSubscription,
  ISubscriptionData,
  ProductsState,
  reSubscription,
} from './types';
import { ISubscription } from 'app/Model/subscription';

export const initialState: ProductsState = {
  result: [],
  isProductLoading: false,
  subscriptionData: undefined,
  productModalState: false,
  productPagination: {
    limit: 10,
    offset: 1,
  },
  subscriptions: [],
  isSubLoading: false,
};

const slice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    getProducts(state, action: PayloadAction<string>) {
      state.isProductLoading = true;
    },
    setProductState(state, action: PayloadAction<IProductModel[]>) {
      state.result = action.payload;
    },
    setSubscriptionData(state, action: PayloadAction<ISubscriptionData>) {
      state.subscriptionData = action.payload;
    },
    subscribeToProduct(state, action: PayloadAction<ICreateSubscription>) {
      state.isProductLoading = true;
    },
    setIsProductLoading(state, action: PayloadAction<boolean>) {
      state.isProductLoading = action.payload;
    },
    clearSubscriptionData(state) {
      state.subscriptionData = undefined;
    },
    toggleProductModalState(state, action: PayloadAction<boolean>) {
      state.productModalState = action.payload;
    },
    setPageNo(state, action: PayloadAction<number>) {
      state.productPagination = {
        offset: action.payload,
        limit: state.productPagination?.limit,
      };
    },
    getSusbscriptions(state, action: PayloadAction<string>) {
      state.isSubLoading = true;
    },
    setSubscription(state, action: PayloadAction<ISubscription[]>) {
      state.subscriptions = action.payload;
    },
    setIsSubscriptionLoading(state, action: PayloadAction<boolean>) {
      state.isSubLoading = action.payload;
    },
    reSubscribeToProduct(state, action: PayloadAction<reSubscription>) {
      state.isProductLoading = true;
    },
  },
});

export const { actions: productActions } = slice;
export const useProductSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: productSaga });
  return { actions: slice.actions };
};
export const productReducers = slice.reducer;

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useCartSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
