import React, { useEffect, useRef, useState } from 'react';
import { BoxWithImage } from './BoxWithImage';
import { UcoverDasets } from 'assets/images';
import { Code } from 'assets/images';
import { DailyUpdates } from 'assets/images';
import { set } from 'shelljs';
// import {  } from "iconoir-react";

const featureData = [
  {
    image: UcoverDasets,
    title: 'Uncover Datasets',
    description:
      'Gain an unbeatable edge by accessing a world of untapped financial data.',
  },
  {
    image: Code,
    title: 'Machine Learning',
    description:
      'Complex financial and economic data are transformed into actionable insights.',
  },
  {
    image: DailyUpdates,
    title: 'Process Automation',
    description:
      'Replace your existing processes with cheaper and more accurate methods.',
  },
];

export const AnimationBox = () => {
  const [text, setText] = useState('');
  const [secondText, setSecondText] = useState('');
  const [showCursor, setShowCursor] = useState(true);
  const originalText = 'pip install';
  const secondOriginalText = 'sovai';
  const textRef = useRef(null);
  const secondTextRef = useRef(null);

  useEffect(() => {
    let index = 0;
    let secondIndex = 0;
    let interval;

    const startInterval = () => {
      interval = setInterval(() => {
        if (index <= originalText.length) {
          setText(originalText.substring(0, index));
          index++;
        } else if (
          index >= originalText.length &&
          secondIndex <= secondOriginalText.length
        ) {
          setSecondText(secondOriginalText.substring(0, secondIndex));
          secondIndex++;
        } else {
          clearInterval(interval);
          setTimeout(() => {
            setText('');
            setSecondText('');
            index = 0;
            secondIndex = 0;
            startInterval();
          }, 4000);
        }
      }, 250);
    };

    startInterval();

    return () => clearInterval(interval);
  }, [originalText, secondOriginalText]);

  useEffect(() => {
    const cursorInterval = setInterval(() => {
      setShowCursor(prev => !prev);
    }, 600);

    return () => clearInterval(cursorInterval);
  }, []);

  useEffect(() => {
    if (textRef.current) {
      (textRef.current as HTMLElement).innerHTML = text;
    }
    if (secondTextRef.current) {
      (secondTextRef.current as HTMLElement).innerHTML = secondText;
    }
  }, [text, secondText]);

  return (
    <div
      id="features"
      className="flex flex-col gap-y-2 items-center justify-center bg-custom-gradient py-12 lg:py-20"
    >
      <div className="text-2xl md:text-3xl lg:text-4xl text-white w-4/5 md:w-3/5 xl:w-2/5 text-center flex flex-row justify-center items-center font-bold">
        Why Choose
      </div>
      <div className="font-manrope flex flex-row justify-center items-center text-center text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-bold bg-gradient-to-r-custom text-gradient leading-extra-loose p-2">
        SOVAI
      </div>
      <div className="text-sm md:text-base lg:text-lg text-white w-4/5 md:w-3/5 xl:w-2/5 text-center flex flex-row justify-center items-center">
        The latest quantitative research tools for fundamental investors.
      </div>

      <div className="flex flex-row text-white justify-center items-center gap-2 mt-4 lg:mt-8 border border-[#333333] p-3 text-base lg:text-lg font-medium">
        <div>$</div>
        <div id="typewriter" ref={textRef}></div>
        <span className="text-[#3ab9d9]" ref={secondTextRef}></span>
        <span className="w-[1px] -ml-2 bg-black inline-block">
          {showCursor && '|'}
        </span>
      </div>
      <div className="flex flex-col sm:flex-row justify-center items-center gap-y-8 sm:gap-y-0 sm:gap-x-2 md:gap-x-8 lg:gap-x-16 w-11/12 lg:w-4/5 mt-4 lg:mt-8">
        {featureData.map((feature, index) => (
          <BoxWithImage props={feature} key={index} />
        ))}
      </div>
    </div>
  );
};
