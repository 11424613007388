import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useProductSlice } from './slice';
import { selectProducts } from './slice/selectors';
import Loading from './loading';
import loading from 'assets/lotties/loading.json';
import RenderProduct from 'app/components/ProductsCard';
import RenderSubscribedProduct from 'app/components/Subscribed';
import Lottie from 'lottie-react';
import { useAuth0 } from '@auth0/auth0-react';
import { HomeLayout } from 'app/components/HomeLayout';
import { ContentBox } from 'app/components/ContentBox';

export function Home() {
  const dispatch = useDispatch();
  const { actions } = useProductSlice();
  const { user } = useAuth0();
  const {
    result,
    isProductLoading,
    subscriptionData,
    productModalState,
    subscriptions,
    isSubLoading,
  } = useSelector(selectProducts);

  useEffect(() => {
    dispatch(actions.getProducts(user?.sub!));
    dispatch(actions.getSusbscriptions(user?.sub!));
  }, []);

  if (subscriptionData) {
    window.location.href = subscriptionData.url;
    dispatch(actions.clearSubscriptionData());
  }

  const lottieDefaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    renderSettings: {
      preserveAspectRation: 'xMidYMid slice',
    },
  };

  return (
    <HomeLayout>
      <div className="flex flex-col items-start justify-start text-white px-8">
        <div className="flex flex-row justify-center items-center text-center text-3xl md:text-4xl font-bold bg-gradient-to-r-custom text-gradient leading-extra-loose p-2">
          Welcome, {user?.given_name || 'User'}
        </div>
        <div className="w-full p-2 mt-3">
          <div className="font-bold text-2xl">Active Plans</div>
          <div className="font-bold text-2xl mt-6 w-full">
            {isSubLoading ? (
              <Loading iteration={1} />
            ) : isSubLoading && subscriptions?.length === 0 ? (
              <Lottie
                animationData={lottieDefaultOptions.animationData}
                loop={lottieDefaultOptions.loop}
                autoplay={lottieDefaultOptions.autoplay}
              />
            ) : !isSubLoading && subscriptions?.length === 0 ? (
              <div className="max-w-[320px]">
                <ContentBox className="text-sm">No Active Plans</ContentBox>
              </div>
            ) : (
              <div className="w-full flex flex-row flex-wrap gap-x-[2%] gap-y-4">
                {subscriptions?.map(sub => {
                  if (sub.status === 'active' || sub.status === 'trialing') {
                    return (
                      <div className="max-w-[320px] w-full lg:w-[45%] xl:w-[30%]">
                        <RenderSubscribedProduct
                          isTrial={sub.status === 'trialing'}
                          sub={sub}
                          isSubLoading={isSubLoading}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <ContentBox className="text-sm">
                        Your Subscription is incomplete!
                      </ContentBox>
                    );
                  }
                })}
              </div>
            )}
          </div>
        </div>
        <div className="w-full p-2 mt-3">
          <div className="font-bold text-2xl">Products</div>
          <div className="font-bold text-2xl mt-6 w-full mb-10">
            {!result ? (
              <Loading iteration={1} />
            ) : result?.length === 0 ? (
              <div className="flex flex-row flex-wrap gap-x-[2%] gap-y-4">
                <div className="max-w-[400px] max-h-[500px] lg:gap-y-4 w-full sm:w-[300px] lg:w-[350px] xl:w-[32%]">
                  <Lottie
                    animationData={lottieDefaultOptions.animationData}
                    loop={lottieDefaultOptions.loop}
                    autoplay={lottieDefaultOptions.autoplay}
                  />
                </div>
              </div>
            ) : (
              <div className="flex flex-row flex-wrap gap-x-[2%] gap-y-4">
                {result?.map((product, index) => {
                  return (
                    <div className="max-w-[400px] max-h-[500px] lg:gap-y-4 w-full sm:w-[300px] lg:w-[350px] xl:w-[40%]">
                      <RenderProduct
                        key={product?.id}
                        product={product}
                        isProductLoading={isProductLoading}
                        modalOpen={productModalState}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </HomeLayout>
  );
}
