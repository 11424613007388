import React from 'react';
import { ArrowRight, MinimalCoding } from 'assets/images';
import { Button } from 'app/components/Button';
import { useNavigate } from 'react-router-dom';

export const SoftwareSignals = () => {
  const navigate = useNavigate();

  const openDocumentation = () => {
    window.open('https://docs.sov.ai/', '__blank');
  };

  return (
    <div className="flex flex-col gap-y-2 items-center justify-center">
      <div
        className="flex flex-row justify-center items-center mt-10 xl:mt-20 gap-1 cursor-pointer"
        onClick={openDocumentation}
      >
        <div className="flex text-white text-base lg:text-xl font-bold">
          Data & Screens
        </div>
        <div className="flex w-4 h-4 md:w-5 md:h-5">
          <img src={ArrowRight} alt="right-arrow" className="w-full h-full" />
        </div>
      </div>
      <div className="flex flex-row justify-center items-center text-center text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-bold bg-gradient-to-r-custom text-gradient leading-extra-loose p-2 pb-4">
        Python for Investment Insights
      </div>
      <div className="text-sm md:text-xl lg:text-2xl text-white w-4/5 md:w-3/5 xl:w-2/5 text-center flex flex-row justify-center items-center">
        Unlock investment insights through machine learning and alternative data analytics.
      </div>
      <div className="flex flex-row items-center justify-center gap-4 md:gap-8 mt-4">
        <Button
          type="gradient"
          onClick={() => {
            navigate('/home');
          }}
        >
          Subscribe Here
        </Button>
        <Button
          onClick={openDocumentation}
        >
          Documentation
        </Button>
      </div>
      <div className="flex flex-row justify-center items-center gap-2 mt-8">
        <div className="flex w-5 h-5">
          <img
            src={MinimalCoding}
            alt="right-arrow"
            className="w-full h-full"
          />
        </div>
        <div className="flex text-white text-base font-bold">
          Minimal Coding Experience Required
        </div>
      </div>
      <div className="flex flex-row justify-center items-center gap-2 w-11/12 md:w-8/12 max-w-[1200px]">
        <img
          src="https://cdn.dorik.com/60fa5a0723068000111d7544/60fa5bbd23068000111d754a/images/c12-(2)_v1t1iwq7.svg"
          alt="right-arrow"
          className="w-full h-full"
        />
      </div>
    </div>
  );
};
