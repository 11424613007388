import { Reversed, Done, MaskGrp } from 'assets/images';
import { Button } from 'app/components/Button';

export const ReversedCard = () => {
  return (
    <div
      className="w-full h-full bg-cover flex flex-col md:flex-row items-center justify-center md:gap-x-4 py-12 px-4 lg:py-20 lg:px-10"
      style={{
        backgroundImage: `url(${Reversed})`,
      }}
    >
      <div className="flex flex-col md:flex-row w-4/5 items-center justify-center">
        <div className="flex flex-col justify-start items-start gap-y-5 md:gap-y-3 lg:gap-y-4 xl:gap-y-8">
          <div className="text-lg text-3xl md:text-2xl lg:text-3xl xl:text-4xl text-white flex flex-row font-bold">
            How does it Work?
          </div>
          <div className="text-sm md:text-sm lg:text-base xl:text-lg text-white flex flex-row">
            We release quarterly datasets and machine learning strategies to
            subscribed customers.
          </div>
          <div className="flex flex-row items-center justify-center gap-x-2 text-white text-base lg:text-lg xl:text-xl">
            <div className="w-4 h-4">
              <img src={Done} alt="w-full h-full" />
            </div>
            <div>
              <span className="font-bold">Step 1: </span>Choose a dataset you
              are interested in
            </div>
          </div>
          <div className="flex flex-row items-center justify-center gap-x-2 text-white text-base lg:text-lg xl:text-xl">
            <div className="w-4 h-4">
              <img src={Done} alt="w-full h-full" />
            </div>
            <div>
              <span className="font-bold">Step 2: </span>Extract signals from
              the datasets
            </div>
          </div>
          <div className="flex flex-row items-center justify-center gap-x-2 text-white text-base lg:text-lg xl:text-xl">
            <div className="w-4 h-4">
              <img src={Done} alt="w-full h-full" />
            </div>
            <div>
              <span className="font-bold">Step 3: </span>Use statistical
              software to enchance the alpha
            </div>
          </div>
          <div className="flex flex-row justify-center items-center">
            <Button
              onClick={() => {
                window.open(
                  'https://docs.sov.ai/get-started/tutorials',
                  '__blank',
                );
              }}
            >
              See Example Notebooks
            </Button>
          </div>
        </div>
        <div className="flex flex-coljustify-center items-center">
          <div className="md:w-[400px] lg:w-[480px] xl:w-[550px]">
            <img src={MaskGrp} alt="maksk-group w-full h-full" />
          </div>
        </div>
      </div>
    </div>
  );
};
