import React from 'react';
import { BoxWithImage } from './BoxWithImage';
import {
  DailyUpdates,
  CodeBg,
  LatestMethods,
  Quantamental,
} from 'assets/images';
import {} from 'assets/images';
import {
  FeatureProps,
  PlanProps,
} from 'app/components/types/ResponsiveResearch';
import { PlanBox } from './PlanBox';

const featureData: FeatureProps[] = [
  {
    image: DailyUpdates,
    title: 'Daily updates',
    description: 'Instantly incorporate new datasets into your workflow.',
  },
  {
    image: LatestMethods,
    title: 'Latest methods',
    description:
      'Effortlessly tap into the latest research for machine learning.',
  },
  {
    image: Quantamental,
    title: 'Quantamental',
    description: 'Uniquely designed for fundamental investors.',
  },
];

const planData: PlanProps[] = [
  {
    title: 'Professional Plan',
    description: '',
    price: '285',
    features: [
      {
        feature: 'Unlimited queries per month',
        available: true,
      },
      {
        feature: 'Access to all datasets',
        available: true,
      },
      {
        feature: "1 user (that's you!)",
        available: true,
      },
      {
        feature: 'Commercial use',
        available: true,
      },
      {
        feature: 'Dedicated support',
        available: false,
      },
    ],
  },
  {
    title: 'Corporate Plan',
    description: '',
    price: '1899',
    features: [
      {
        feature: 'Unlimited queries per month',
        available: true,
      },
      {
        feature: 'Access to all datasets',
        available: true,
      },
      {
        feature: '5 users (different IPs)',
        available: true,
      },
      {
        feature: 'Commercial use',
        available: true,
      },
      {
        feature: 'Dedicated support',
        available: true,
      },
    ],
  },
];

export const ResponsiveResearch = () => {
  return (
    <div className="flex flex-col items-center justify-center bg-custom-gradient py-12 lg:py-20">
      <div className="w-4/5 md:w-3/5 xl:w-7/12">
        <div className="text-2xl lg:text-3xl xl:text-4xl text-white text-center flex flex-row justify-center items-center font-bold">
          Quant Research for Fundamenal Investors
        </div>
        <div className="mt-12 text-sm md:text-base lg:text-xl text-white text-center flex flex-row justify-center items-center">
          Explore more than 10+ and growing unique datasets for equity
          selection, similarity analysis, forecasting, risk allocation, and weight optimization.
        </div>
      </div>

      <div className="flex flex-row justify-center items-center lg:w-9/12">
        <img src={CodeBg} alt="code-example" />
      </div>

      {/* <div className="w-full flex flex-col items-center justify-center gap-y-12">
        <div className="text-2xl lg:text-3xl xl:text-4xl text-white text-center flex flex-row justify-center items-center font-bold">
          Integrates with your favorite database
        </div>

        <div className="flex flex-col sm:flex-row justify-center items-center gap-y-8 sm:gap-y-0 sm:gap-x-2 md:gap-x-8 lg:gap-x-16 w-11/12 lg:w-4/5">
          {featureData.map((feature, index) => (
            <BoxWithImage props={feature} key={index} />
          ))}
        </div>
      </div> */}

      <div
        id="pricing"
        className="w-full flex flex-col items-center justify-center gap-y-12 mt-16"
      >
        <div className="text-2xl lg:text-3xl xl:text-4xl text-white text-center flex flex-row justify-center items-center font-bold">
            A straightforward payment plan
        </div>

        <div className="flex flex-col sm:flex-row justify-center items-center gap-y-8 sm:gap-y-0 sm:gap-x-2 md:gap-x-8 lg:gap-x-16 w-11/12 lg:w-4/5">
          {planData.map((plan, index) => (
            <PlanBox props={plan} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};
