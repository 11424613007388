import { call, put, select, takeLatest } from 'redux-saga/effects';

import { PayloadAction } from '@reduxjs/toolkit';
import makeCall from 'app/API/makeCalls';
import routes from 'app/API/api.routes';
import { TypeOptions } from 'react-toastify';
import { v4 as uuidV4 } from 'uuid';
import { defaultLayoutActions } from 'app/pages/DefaultLayout/slice';
import {
  ICreateSubscription,
  ISetProduct,
  ISubscriptionData,
  reSubscription,
} from './types';
import { productActions as actions } from '.';
import {
  DEFAULT_DESCRIPTION,
  DEFAULT_TITLE,
  VARIANTS,
} from 'app/pages/DefaultLayout/constants';
import { ISetSubscription } from 'app/pages/SubscriptionPage/slice/types';

function* createSubscriptionSaga(action: PayloadAction<ICreateSubscription>) {
  try {
    const response: ISubscriptionData = yield call(makeCall, {
      method: 'POST',
      route: routes.subscribe.list,
      body: {
        price_id: action.payload.priceId,
        user_id: action.payload.userId,
      },
    });
    if (response) {
      yield put(actions.setSubscriptionData(response));
      yield put(actions.setIsProductLoading(false));
      yield put(actions.toggleProductModalState(false));
      yield put(
        defaultLayoutActions.setAppMessage({
          currentMessage:
            'Congratulations! You have successfully subscribed to the app.',
          currentTitle: DEFAULT_TITLE.Success,
          currentVariant: VARIANTS.SUCCESS as TypeOptions,
          count: uuidV4(),
        }),
      );
    }
  } catch (error: any) {
    yield put(actions.toggleProductModalState(false));
    yield put(
      defaultLayoutActions.setAppMessage({
        currentMessage: 'Something went wrong!',
        currentTitle: DEFAULT_TITLE.Fail,
        currentVariant: VARIANTS.Error as TypeOptions,
        count: uuidV4(),
      }),
    );
    yield put(actions.setIsProductLoading(false));
  }
}
function* reSubscriptionSaga(action: PayloadAction<reSubscription>) {
  try {
    const response: ISubscriptionData = yield call(makeCall, {
      method: 'PUT',
      route: `${routes.subscribe.list}/${action.payload.id}`,
    });
    if (response) {
      // yield put(actions.setSubscriptionData(response));
      yield put(actions.setIsProductLoading(false));
      yield put(
        defaultLayoutActions.setAppMessage({
          currentMessage:
            'Congratulations! You have successfully subscribed to the app.',
          currentTitle: DEFAULT_TITLE.Success,
          currentVariant: VARIANTS.SUCCESS as TypeOptions,
          count: uuidV4(),
        }),
      );
      window.location.reload();
    }
  } catch (error: any) {
    yield put(actions.toggleProductModalState(false));
    yield put(
      defaultLayoutActions.setAppMessage({
        currentMessage: 'Something went wrong!',
        currentTitle: DEFAULT_TITLE.Fail,
        currentVariant: VARIANTS.Error as TypeOptions,
        count: uuidV4(),
      }),
    );
    yield put(actions.setIsProductLoading(false));
  }
}

function* getProductsSaga(action: PayloadAction<string>) {
  try {
    const response: ISetProduct = yield call(makeCall, {
      method: 'GET',
      route: `${routes.product.list}`,
      // route: `${routes.product.list}?user_id=${action.payload}`,
    });
    if (
      response?.result &&
      Array.isArray(response?.result) &&
      response?.result?.length
    ) {
      yield put(actions.setProductState(response?.result));
    }
    yield put(actions.setIsProductLoading(false));
  } catch (error: any) {
    yield put(actions.setIsProductLoading(false));
    console.log('Err', error);
  }
}

function* getSubscriptionsSaga(action: PayloadAction<string>) {
  try {
    const response: ISetSubscription = yield call(makeCall, {
      method: 'GET',
      route: routes.subscribe.list,
    });
    if (
      response?.result &&
      Array.isArray(response?.result) &&
      response?.result?.length
    ) {
      yield put(actions.setSubscription(response?.result));
    }
    yield put(actions.setIsSubscriptionLoading(false));
  } catch (error: any) {
    yield put(actions.setIsSubscriptionLoading(false));
    console.log('Err', error);
  }
}

export function* productSaga() {
  yield takeLatest(actions.getProducts.type, getProductsSaga);
  yield takeLatest(actions.subscribeToProduct.type, createSubscriptionSaga);
  yield takeLatest(actions.reSubscribeToProduct.type, reSubscriptionSaga);
  yield takeLatest(actions.getSusbscriptions.type, getSubscriptionsSaga);
}
